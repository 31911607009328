<template>
    <v-container
        fluid
    >
        <v-row>
            <v-col
                cols="12"
                md="8"
            >
                <h2>{{ $t('cookie-info') }}</h2>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    computed: {
    },
    created() {        
    },    
    methods: {
    }
}
</script>
